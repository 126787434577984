import type { ErrorResponse } from '@iheartradio/web.remix-shared/error/types/error-response.js';

import { AppErrorBoundary } from '~app/components/error/app-error-boundary';

export default function NotFound() {
  return (
    <AppErrorBoundary
      errorOverride={
        {
          status: 404,
          statusText: '',
          internal: false,
          data: null,
        } as ErrorResponse
      }
    />
  );
}
